import {
  TOGGLE_MENU,
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  SET_THEME,
  SET_NAVIGATION_DATA,
  SEARCH,
  STUDY_DETAILS,
  SET_CURRENT_SCREEN,
  SET_SUPPLY_DATA,
  SET_ROLECONFIG_DATA,
  SET_USERROLECONFIG_DATA,
  IS_LANDING,
  SHOW_FEEDBACK,
  SELECTED_ITEM,
  COMPOUND_LIST,
  SELECTED_OPTION,
  IS_EXPAND,
  SELECTED_TYPE,
  SET_ALERT_MATERIAL,
  NODE_ID,
  SET_COMPOUND_ID,
  SET_LINK_OBJECT,
  ACTUAL_SUBJECT,
  SHOW_CHART,
  EXPECTED_RESOLUTION_SLIDE,
  TESTVALUESDATA,
  INITIAL_lOAD_OPEN_VERSION,
  SET_Filter_DATA,
  SET_COMPOUND_LIST,
  SET_POTFOILIO,
  SET_SUB_CATEGORY,
  SET_RISK_LEVEL,
  SET_MILESTONE_DATA,
  RISK_ACTION,
  CHECK_LIST_TOGGEL,
  FGDATA,
  SET_SUMMARY_DATA,
  TOGGLE_CLOSED,
  RESET_SHOW_CLOSED,
  FGCOUNTRYDATA,
  COMPLEXITY_LEVEL,
  RISK_ACTION_SUMMARY_FILTER,
  GET_DEMAND_AND_SUPPLY_TABLEDATA_STARTED,
  GET_DEMAND_AND_SUPPLY_TABLEDATA_SUCCESS,
  GET_DEMAND_AND_SUPPLY_TABLEDATA_FAILED,
  GET_INVENTORY_AND_SITE_SHIPEMENTDATA_STARTED,
  GET_INVENTORY_AND_SITE_SHIPEMENTDATA_SUCCESS,
  GET_INVENTORY_AND_SITE_SHIPEMENTDATA_FAILED,

  GET_STUDY_DESIGN_COMPLEXITY_BYID_STARTED,
  GET_STUDY_DESIGN_COMPLEXITY_BYID_SUCCESS,
  GET_STUDY_DESIGN_COMPLEXITY_BYID_FAILED,
  UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_STARTED,
  UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_SUCCESS,
  UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_FAILED,
  GET_PRODUCT_COMPLEXITY_TABLEDATA_STARTED,
  GET_PRODUCT_COMPLEXITY_TABLEDATA_SUCCESS,
  GET_PRODUCT_COMPLEXITY_TABLEDATA_FAILED,
  GET_SUPPLY_COMPLEXITY_TABLEDATA_STARTED,
  GET_SUPPLY_COMPLEXITY_TABLEDATA_SUCCESS,
  GET_SUPPLY_COMPLEXITY_TABLEDATA_FAILED,
  UPDATE_PRODUCT_COMPLEXITY_BYID_STARTED,
  UPDATE_PRODUCT_COMPLEXITY_BYID_SUCCESS,
  UPDATE_PRODUCT_COMPLEXITY_BYID_FAILED,
  UPDATE_SUPPLY_COMPLEXITY_BYID_STARTED,
  UPDATE_SUPPLY_COMPLEXITY_BYID_SUCCESS,
  UPDATE_SUPPLY_COMPLEXITY_BYID_FAILED,

  GET_BCSR_TABLEDATA_STARTED,
  GET_BCSR_TABLEDATA_SUCCESS,
  GET_BCSR_TABLEDATA_FAILED,
  ADD_BCSR_FORMDATA_STARTED,
  ADD_BCSR_FORMDATA_SUCCESS,
  ADD_BCSR_FORMDATA_FAILED,
  UPDATE_BCSR_DATA_BYID_STARTED,
  UPDATE_BCSR_DATA_BYID_SUCCESS,
  UPDATE_BCSR_DATA_BYID_FAILED,
  GET_ONE_BCSR_DATA_BYID_STARTED,
  GET_ONE_BCSR_DATA_BYID_SUCCESS,
  GET_ONE_BCSR_DATA_BYID_FAILED,
  GET_MULTIPLE_DEPOT_SELECTION,
  SET_COMPOUNDLIST_FROM_LANDLING_SCREEN,
  FAILED_ORDER_DEPOTLIST_STARTED,
  FAILED_ORDER_DEPOTLIST_SUCCESS,
  FAILED_ORDER_DEPOTLIST_FAILED,
  FAILED_ORDER_DEPOTLIST_NOTSTARTED
} from "../types/types";

import { getIBPTableData } from "../../api/report";
import { getInventoryAndSiteShipmentsDetailsForDigitalTwin } from "../../api/DigitalTwin/Country"

import {
  getStudyDesignComplexity,
  updateStudyDesignComplexity,
  getSupplyComplexityData,
  getProductComplexityData,
  saveSupplyComplexity
  , saveProductComplexity
} from '../../api/StudyDetails/complexityApis'

import {
  getBCSRTableDataAPI,
  addBCSRFormDataAPI,
  getBCSRDataByIDAPI,
  updateBCSRDataByIDAPI
} from '../../api/businessRisk';

import { getFailedorderDepotsApi } from "../../api/countryspecificrisk";

export const toggleMenu = () => (dispatch) => {
  dispatch({
    type: TOGGLE_MENU,
  });
};
export const showLoader = () => (dispatch) => {
  dispatch({
    type: SHOW_LOADING,
    payload: true,
  });
};
export const hideLoader = () => (dispatch) => {
  dispatch({
    type: HIDE_LOADING,
    payload: false,
  });
};
export const showNotification = (type, message, description) => (dispatch) => {
  dispatch({
    type: SHOW_NOTIFICATION,
    payload: {
      type,
      message,
      description,
      status: true,
    },
  });
};
export const hideNotification = () => (dispatch) => {
  dispatch({
    type: HIDE_NOTIFICATION,
    payload: {
      status: false,
    },
  });
};

export const setTheme = (theme) => (dispatch) => {
  dispatch({
    type: SET_THEME,
    payload: theme,
  });
};

export const setNavigationData = (data) => (dispatch) => {
  dispatch({
    type: SET_NAVIGATION_DATA,
    payload: data,
  });
};

export const searchData = (text) => (dispatch) => {
  dispatch({
    type: SEARCH,
    payload: text,
  });
};
export const complexityLevelData = (text) => (dispatch) => {
  dispatch({
    type: COMPLEXITY_LEVEL,
    payload: text
  })
}
export const selectedoption = (text) => (dispatch) => {
  dispatch({
    type: SELECTED_OPTION,
    payload: text,
  });
};
export const setCurrentStudyDetails = (data) => (dispatch) => {
  dispatch({
    type: STUDY_DETAILS,
    payload: data,
  });
};
export const setCurrentScreen = (text) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_SCREEN,
    payload: text,
  });
};

export const FGValues = (text) => (dispatch) => {
  dispatch({
    type: FGDATA,
    payload: text,
  });
};

export const setSupplyData = (text) => (dispatch) => {
  dispatch({
    type: SET_SUPPLY_DATA,
    payload: text,
  });
};

export const setRoleConfigData = (text) => (dispatch) => {
  dispatch({
    type: SET_ROLECONFIG_DATA,
    payload: text,
  });
};

export const userRoleConfigData = (text) => (dispatch) => {
  dispatch({
    type: SET_USERROLECONFIG_DATA,
    payload: text,
  });
};

export const isLanding = (text) => (dispatch) => {
  dispatch({
    type: IS_LANDING,
    payload: text,
  });
};

export const showFeedback = () => (dispatch) => {
  dispatch({
    type: SHOW_FEEDBACK,
  });
};

export const SetselectedItem = (text) => (dispatch) => {
  dispatch({
    type: SELECTED_ITEM,
    payload: text,
  });
};

export const compoundList = (text) => (dispatch) => {
  dispatch({
    type: COMPOUND_LIST,
    payload: text,
  });
};

export const showExpandView = (text) => (dispatch) => {
  dispatch({
    type: IS_EXPAND,
    payload: text,
  });
};

export const selectedFlowType = (text) => (dispatch) => {
  dispatch({
    type: SELECTED_TYPE,
    payload: text,
  });
};

export const setAlertMaterial = (text) => (dispatch) => {
  dispatch({
    type: SET_ALERT_MATERIAL,
    payload: text,
  });
};

export const setNodeId = (text) => (dispatch) => {
  dispatch({
    type: NODE_ID,
    payload: text,
  });
};

export const setCompoundId = (text) => (dispatch) => {
  dispatch({
    type: SET_COMPOUND_ID,
    payload: text,
  });
};

export const setLinkObject = (text) => (dispatch) => {
  dispatch({
    type: SET_LINK_OBJECT,
    payload: text,
  });
};

export const getActaulSubject = (text) => (dispatch) => {
  dispatch({
    type: ACTUAL_SUBJECT,
    payload: text,
  });
};

export const showChartWidget = (text) => (dispatch) => {
  dispatch({
    type: SHOW_CHART,
    payload: text,
  });
};

export const expectedResolutionSlideVal = (text) => (dispatch) => {
  dispatch({
    type: EXPECTED_RESOLUTION_SLIDE,
    payload: text,
  });
};

export const testValues = (text) => (dispatch) => {
  dispatch({
    type: TESTVALUESDATA,
    payload: text,
  });
};

export const allFilterData = (text) => (dispatch) => {
  dispatch({
    type: SET_Filter_DATA,
    payload: text,
  });
};

export const openInitialVersion = (text) => (dispatch) => {
  dispatch({
    type: INITIAL_lOAD_OPEN_VERSION,
    payload: text,
  });
};
export const compoundCategory = (text) => (dispatch) => {
  dispatch({
    type: SET_COMPOUND_LIST,
    payload: text,
  });
};

export const AllPortFolioList = (text) => (dispatch) => {
  dispatch({
    type: SET_POTFOILIO,
    payload: text,
  });
};

export const SelectedSubCategory = (text) => (dispatch) => {
  dispatch({
    type: SET_SUB_CATEGORY,
    payload: text,
  });
};

export const RiskActionItemSummary = (text) => (dispatch) => {
  dispatch({
    type: RISK_ACTION,
    payload: text,
  });
};

export const MultipleDepotSelectionData = (text) => (dispatch) => {
  dispatch({
    type: GET_MULTIPLE_DEPOT_SELECTION,
    payload: text,
  });
};

export const ChecklistToggel = (text) => (dispatch) => {
  dispatch({
    type: CHECK_LIST_TOGGEL,
    payload: text,
  });
};

//for action summary 
export const ItemSummaryData = (data) => (dispatch) => {
  dispatch({
    type: SET_SUMMARY_DATA,
    data,
  });
};
//FOR RISK Action item 
export const riskActionItemPayload = (data) => (dispatch) => {
  dispatch({
    type: RISK_ACTION_SUMMARY_FILTER,
    payload: data
  })
}
//for milestone
export const changeMilestoneData = (data) => (dispatch) => {
  dispatch({
    type: SET_MILESTONE_DATA,
    payload: data
  })
}

//for risk_level
export const changeRiskLevel = (text) => (dispatch) => {
  dispatch({
    type: SET_RISK_LEVEL,
    payload: text
  })
}
export const toggleClosed = (text) => (dispatch) => {
  dispatch({
    type: TOGGLE_CLOSED,
    payload: text,
  });
};


export const resetShowClosed = () => ({
  type: RESET_SHOW_CLOSED
});

export const FGCountry = (text) => (dispatch) => {
  dispatch({
    type: FGCOUNTRYDATA,
    payload: text,
  });
};


export const getDemandSupplyTableData = (values) =>
  (dispatch, getState) => {
    dispatch({ type: GET_DEMAND_AND_SUPPLY_TABLEDATA_STARTED, payload: false });

    getIBPTableData(values)
      .then((res) => {
        //Auth.login(res);
        dispatch({ type: GET_DEMAND_AND_SUPPLY_TABLEDATA_SUCCESS, payload: res });
      })
      .catch((error) => {
        if (error.name === "ServerError")
          dispatch({ type: GET_DEMAND_AND_SUPPLY_TABLEDATA_FAILED, payload: error.message });
        else if (error.name === "SignupError")
          dispatch({ type: GET_DEMAND_AND_SUPPLY_TABLEDATA_FAILED, payload: error.message });
        else dispatch({ type: GET_DEMAND_AND_SUPPLY_TABLEDATA_FAILED, payload: error.message });
      });
  };

export const getInventorySiteShipmentTableData = (values) =>
  (dispatch, getState) => {
    dispatch({ type: GET_INVENTORY_AND_SITE_SHIPEMENTDATA_STARTED, payload: false });

    getInventoryAndSiteShipmentsDetailsForDigitalTwin(values)
      .then((res) => {
        //Auth.login(res);
        dispatch({ type: GET_INVENTORY_AND_SITE_SHIPEMENTDATA_SUCCESS, payload: res.data });
      })
      .catch((error) => {
        if (error.name === "ServerError")
          dispatch({ type: GET_INVENTORY_AND_SITE_SHIPEMENTDATA_FAILED, payload: error.message });
        else if (error.name === "SignupError")
          dispatch({ type: GET_INVENTORY_AND_SITE_SHIPEMENTDATA_FAILED, payload: error.message });
        else dispatch({ type: GET_INVENTORY_AND_SITE_SHIPEMENTDATA_FAILED, payload: error.message });
      });
  };


export const getStudyDesignComplexityDataByIDAction = (values) =>
  (dispatch, getState) => {
    dispatch({ type: GET_STUDY_DESIGN_COMPLEXITY_BYID_STARTED, payload: false });

    getStudyDesignComplexity(values)
      .then((res) => {
        dispatch({ type: GET_STUDY_DESIGN_COMPLEXITY_BYID_SUCCESS, payload: res });
      })
      .catch((error) => {
        if (error.name === "ServerError")
          dispatch({ type: GET_STUDY_DESIGN_COMPLEXITY_BYID_FAILED, payload: error.message });
        else if (error.name === "SignupError")
          dispatch({ type: GET_STUDY_DESIGN_COMPLEXITY_BYID_FAILED, payload: error.message });
        else dispatch({ type: GET_STUDY_DESIGN_COMPLEXITY_BYID_FAILED, payload: error });
      });
  };

export const updateStudyDesignComplexityByIDAction = (values) =>
  (dispatch, getState) => {
    dispatch({ type: UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_STARTED, payload: false });

    updateStudyDesignComplexity(values)
      .then((res) => {
        dispatch({ type: UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_SUCCESS, payload: res });
      })
      .catch((error) => {
        if (error.name === "ServerError")
          dispatch({ type: UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_FAILED, payload: error.message });
        else if (error.name === "SignupError")
          dispatch({ type: UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_FAILED, payload: error.message });
        else dispatch({ type: UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_FAILED, payload: error });
      });
  };

export const getProductComplexityTabledataAction = (values) =>
  (dispatch, getState) => {
    dispatch({ type: GET_PRODUCT_COMPLEXITY_TABLEDATA_STARTED, payload: false });

    getProductComplexityData(values)
      .then((res) => {
        dispatch({ type: GET_PRODUCT_COMPLEXITY_TABLEDATA_SUCCESS, payload: res });
      })
      .catch((error) => {
        if (error.name === "ServerError")
          dispatch({ type: GET_PRODUCT_COMPLEXITY_TABLEDATA_FAILED, payload: error.message });
        else if (error.name === "SignupError")
          dispatch({ type: GET_PRODUCT_COMPLEXITY_TABLEDATA_FAILED, payload: error.message });
        else dispatch({ type: GET_PRODUCT_COMPLEXITY_TABLEDATA_FAILED, payload: error });
      });
  };

export const getSupplyComplexityTabledataAction = (values) =>
  (dispatch, getState) => {
    dispatch({ type: GET_SUPPLY_COMPLEXITY_TABLEDATA_STARTED, payload: false });

    getSupplyComplexityData(values)
      .then((res) => {
        dispatch({ type: GET_SUPPLY_COMPLEXITY_TABLEDATA_SUCCESS, payload: res });
      })
      .catch((error) => {
        if (error.name === "ServerError")
          dispatch({ type: GET_SUPPLY_COMPLEXITY_TABLEDATA_FAILED, payload: error.message });
        else if (error.name === "SignupError")
          dispatch({ type: GET_SUPPLY_COMPLEXITY_TABLEDATA_FAILED, payload: error.message });
        else dispatch({ type: GET_SUPPLY_COMPLEXITY_TABLEDATA_FAILED, payload: error });
      });
  };

export const updateProductComplexityByIDAction = (values) =>
  (dispatch, getState) => {
    dispatch({ type: UPDATE_PRODUCT_COMPLEXITY_BYID_STARTED, payload: false });

    saveProductComplexity(values)
      .then((res) => {
        dispatch({ type: UPDATE_PRODUCT_COMPLEXITY_BYID_SUCCESS, payload: res });
      })
      .catch((error) => {
        if (error.name === "ServerError")
          dispatch({ type: UPDATE_PRODUCT_COMPLEXITY_BYID_FAILED, payload: error.message });
        else if (error.name === "SignupError")
          dispatch({ type: UPDATE_PRODUCT_COMPLEXITY_BYID_FAILED, payload: error.message });
        else dispatch({ type: UPDATE_PRODUCT_COMPLEXITY_BYID_FAILED, payload: error });
      });
  };

export const updateSupplyComplexityByIDAction = (values) =>
  (dispatch, getState) => {
    dispatch({ type: UPDATE_SUPPLY_COMPLEXITY_BYID_STARTED, payload: false });

    saveSupplyComplexity(values)
      .then((res) => {
        dispatch({ type: UPDATE_SUPPLY_COMPLEXITY_BYID_SUCCESS, payload: res });
      })
      .catch((error) => {
        if (error.name === "ServerError")
          dispatch({ type: UPDATE_SUPPLY_COMPLEXITY_BYID_FAILED, payload: error.message });
        else if (error.name === "SignupError")
          dispatch({ type: UPDATE_SUPPLY_COMPLEXITY_BYID_FAILED, payload: error.message });
        else dispatch({ type: UPDATE_SUPPLY_COMPLEXITY_BYID_FAILED, payload: error });
      });
  };

export const getBCSRTableDataAction = (values) =>
  (dispatch, getState) => {
    dispatch({ type: GET_BCSR_TABLEDATA_STARTED, payload: false });

    getBCSRTableDataAPI(values)
      .then((res) => {
        dispatch({ type: GET_BCSR_TABLEDATA_SUCCESS, payload: res });
      })
      .catch((error) => {
        if (error.name === "ServerError")
          dispatch({ type: GET_BCSR_TABLEDATA_FAILED, payload: error.message });
        else if (error.name === "SignupError")
          dispatch({ type: GET_BCSR_TABLEDATA_FAILED, payload: error.message });
        else dispatch({ type: GET_BCSR_TABLEDATA_FAILED, payload: error.message });
      });
  };


export const addBCSRTableFormDataAction = (values) =>
  (dispatch, getState) => {
    dispatch({ type: ADD_BCSR_FORMDATA_STARTED, payload: false });

    addBCSRFormDataAPI(values)
      .then((res) => {
        dispatch({ type: ADD_BCSR_FORMDATA_SUCCESS, payload: res });
      })
      .catch((error) => {
        if (error.name === "ServerError")
          dispatch({ type: ADD_BCSR_FORMDATA_FAILED, payload: error.message });
        else if (error.name === "SignupError")
          dispatch({ type: ADD_BCSR_FORMDATA_FAILED, payload: error.message });
        else dispatch({ type: ADD_BCSR_FORMDATA_FAILED, payload: error });
      });
  };

export const getBCSRDataByIDAction = (values) =>
  (dispatch, getState) => {
    dispatch({ type: GET_ONE_BCSR_DATA_BYID_STARTED, payload: false });

    getBCSRDataByIDAPI(values)
      .then((res) => {
        dispatch({ type: GET_ONE_BCSR_DATA_BYID_SUCCESS, payload: res });
      })
      .catch((error) => {
        if (error.name === "ServerError")
          dispatch({ type: GET_ONE_BCSR_DATA_BYID_FAILED, payload: error.message });
        else if (error.name === "SignupError")
          dispatch({ type: GET_ONE_BCSR_DATA_BYID_FAILED, payload: error.message });
        else dispatch({ type: GET_ONE_BCSR_DATA_BYID_FAILED, payload: error.message });
      });
  };

export const updateBCSRDataByIDAction = (values) =>
  (dispatch, getState) => {
    dispatch({ type: UPDATE_BCSR_DATA_BYID_STARTED, payload: false });

    updateBCSRDataByIDAPI(values)
      .then((res) => {
        dispatch({ type: UPDATE_BCSR_DATA_BYID_SUCCESS, payload: res });
      })
      .catch((error) => {
        if (error.name === "ServerError")
          dispatch({ type: UPDATE_BCSR_DATA_BYID_FAILED, payload: error.message });
        else if (error.name === "SignupError")
          dispatch({ type: UPDATE_BCSR_DATA_BYID_FAILED, payload: error.message });
        else dispatch({ type: UPDATE_BCSR_DATA_BYID_FAILED, payload: error });
      });
  };

export const setCompundListFromLandinScreen = (text) => (dispatch) => {
  dispatch({
    type: SET_COMPOUNDLIST_FROM_LANDLING_SCREEN,
    payload: text,
  });
};

export const failedOrderDepotAction = (values) =>
  (dispatch, getState) => {
    dispatch({ type: FAILED_ORDER_DEPOTLIST_STARTED, payload: false });

    getFailedorderDepotsApi(values)
      .then((res) => {
        dispatch({ type: FAILED_ORDER_DEPOTLIST_SUCCESS, payload: res });
      })
      .catch((error) => {
        if (error.name === "ServerError")
          dispatch({ type: FAILED_ORDER_DEPOTLIST_FAILED, payload: error.message });
        else if (error.name === "SignupError")
          dispatch({ type: FAILED_ORDER_DEPOTLIST_FAILED, payload: error.message });
        else dispatch({ type: FAILED_ORDER_DEPOTLIST_FAILED, payload: error });
      });
  };