//BMS					
//export const API_CUBIX_URL ="https://cubix-service.relevancelab.com/cassa";												
//export const API_ENTITLEMENT_URL = "https://apigateway.relevancelab.com/entitlement";						
//export const API_DASHBOARD_URL = "https://apigateway.relevancelab.com/prismmicro-dashboard";						
//export const API_AUTOCOMPLETE_URL = "https://swagger.mareana.com/v1.0";						
//export const API_AUTHENTICATION_URL = "https://apigateway.relevancelab.com/prismmicro-authentication"						
//export const API_AUTHENTICATION_URL = "http://192.168.101.78:8765/prismmicro-authentication"						
//export const API_SEARCH_URL = "https://apigateway.relevancelab.com/prismmicro-search";						
//export const API_LOGIN_URL = "https://cubix-service.relevancelab.com/rigel-mongo-auth";						
// export const API_CASSA="http://192.168.101.78:9003/api";						
//export const API_CASSA="http://192.168.101.53:8765/clinical-supply-chain/api"						
//export const API_CASSA='https://rigel-api-prism-csc-dev.relevancelab.com'						
//export const API_ALERT="https://rigel-api-gateway-dev.relevancelab.com/prism-alert"
//export const API_ALERT="http://192.168.101.78:9001"
//export const API_AUTHENTICATION_URL = "http://192.168.101.78:9001";
// export const API_AUTHENTICATION_URL = "https://rigel-api-gateway-dev-demo.relevancelab.com/rigel-mongo-auth";
//export const API_CASSA="http://192.168.101.78:8765/spectra-csc"
// export const API_RISK_ALERT="http://localhost:9009"
// export const API_RIGEL_DROOLS="http://192.168.101.55:8765/rigel-drools"
//export const API_CUBIX_URL ="http://192.168.101.78:8765/cassa";
//export const API_RISK_ALERT="https://rigel-api-gateway-dev-demo.relevancelab.com/rigel-alert";						
			        
//cubix url to be removed in future
// export const API_CUBIX_URL ="https://cubix-service.relevancelab.com/cassa";          
// //Authentication apis						
// export const API_AUTHENTICATION_URL = "https://cassa3-api-auth-v2.web-uat.bms.com";
// //Resultset apis 
// export const API_BASE_URL = "https://apigateway.relevancelab.com";	
// //To be confirmed
// export const API_RESULTSET_URL = "http://rigel-api-gateway-dev-demo.relevancelab.com";	
// // the control tower apis
// export const API_CASSA="https://cassa3-0-api-csc.web-uat.bms.com";
// // the demo control tower apis
// export const API_CASSA_DEV_DEMO = "https://cassa3-0-api-csc.web-uat.bms.com";
// //make sure to revaluate
// export const API_ALERT="https://cassa-api-alert.web-uat.bms.com";	
// //bms-logout-url
// export const APP_AUTH="https://cassa3-0.web-uat.bms.com/";	
// //demand-chart
// export const API_RESULTSET_URL_1 = "https://apigateway.relevancelab.com/prism-resultset";
// //export const API_RIGEL_DEV_DEMO = "https://rigel-api-gateway-dev-demo.relevancelab.com";
// //export const API_RIGEL_DEV = "https://rigel-api-gateway-dev.relevancelab.com"	
// //risk alerts for drools
// export const API_RIGEL_DROOLS=`https://cassa3-api-drools.web-uat.bms.com`;
// // risk alerts for mongo
// export const API_RISK_ALERT_MONGO ="https://cassa3-api-alert.web-uat.bms.com"


//RL DEV URLS
export const API_CUBIX_URL ="https://cassa-services-dev.relevancelab.com/cassa-csc";          
//Authentication apis						
export const API_AUTHENTICATION_URL = "https://cassa-services-dev.relevancelab.com/cassa-auth"

//Resultset apis 
export const API_BASE_URL = "https://apigateway.relevancelab.com";	
//To be confirmed
export const API_RESULTSET_URL = "http://rigel-api-gateway-dev-demo.relevancelab.com";	
// the control tower apis
export const API_CASSA="https://cassa-services-dev.relevancelab.com/cassa-csc"
//"https://cassa3-0-api-csc.web-uat.bms.com";
// the demo control tower apis
export const API_CASSA_DEV_DEMO = "https://cassa-services-dev.relevancelab.com/cassa-csc";
//make sure to revaluate
export const API_ALERT="https://cassa-services-dev.relevancelab.com/cassa-csc";	
//bms-logout-url
export const APP_AUTH="https://cassa-auth-dev.relevancelab.com/user/login"
	
//demand-chart
export const API_RESULTSET_URL_1 = "https://apigateway.relevancelab.com/prism-resultset";
//export const API_RIGEL_DEV_DEMO = "https://rigel-api-gateway-dev-demo.relevancelab.com";
//export const API_RIGEL_DEV = "https://rigel-api-gateway-dev.relevancelab.com"	
//risk alerts for drools
export const API_RIGEL_DROOLS=`https://cassa-services-dev.relevancelab.com/cassa-csc`;
// risk alerts for mongo
export const API_RISK_ALERT_MONGO ="https://cassa-services-dev.relevancelab.com/cassa-alert"

export const CLIENT3_A_DEV_DEMO = "https://cassa-services-dev.relevancelab.com/cassa-csc"

// cassa-services-dev.relevancelab.com/cassa-csc
// cassa-services-dev.relevancelab.com/cassa-alert
// cassa-services-dev.relevancelab.com/cassa-auth