import {
  TOGGLE_MENU,
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  SET_THEME,
  SET_NAVIGATION_DATA,
  SEARCH,
  STUDY_DETAILS,
  SET_CURRENT_SCREEN,
  SET_SUPPLY_DATA,
  SET_ROLECONFIG_DATA,
  SET_USERROLECONFIG_DATA,
  IS_LANDING,
  SHOW_FEEDBACK,
  SELECTED_ITEM,
  COMPOUND_LIST,
  SELECTED_OPTION,
  IS_EXPAND,
  SELECTED_TYPE,
  SET_ALERT_MATERIAL,
  NODE_ID,
  SET_COMPOUND_ID,
  SET_LINK_OBJECT,
  ACTUAL_SUBJECT,
  SHOW_CHART,
  EXPECTED_RESOLUTION_SLIDE,
  TESTVALUESDATA,
  INITIAL_lOAD_OPEN_VERSION,
  SET_Filter_DATA,
  SET_COMPOUND_LIST,
  SET_POTFOILIO,
  SET_SUB_CATEGORY,
  RISK_ACTION,
  GET_MULTIPLE_DEPOT_SELECTION,
  CHECK_LIST_TOGGEL,
  FGDATA,
  SET_SUMMARY_DATA,
  TOGGLE_CLOSED,
  RESET_SHOW_CLOSED,
  FGCOUNTRYDATA,
  SET_MILESTONE_DATA,
  SET_RISK_LEVEL,
  COMPLEXITY_LEVEL,
  RISK_ACTION_SUMMARY_FILTER,
  GET_DEMAND_AND_SUPPLY_TABLEDATA_STARTED,
  GET_DEMAND_AND_SUPPLY_TABLEDATA_SUCCESS,
  GET_DEMAND_AND_SUPPLY_TABLEDATA_FAILED,
  GET_DEMAND_AND_SUPPLY_TABLEDATA_NOTSTARTED,
  GET_INVENTORY_AND_SITE_SHIPEMENTDATA_STARTED,
  GET_INVENTORY_AND_SITE_SHIPEMENTDATA_SUCCESS,
  GET_INVENTORY_AND_SITE_SHIPEMENTDATA_FAILED,
  GET_INVENTORY_AND_SITE_SHIPEMENTDATA_NOTSTARTED,

  GET_STUDY_DESIGN_COMPLEXITY_BYID_STARTED,
  GET_STUDY_DESIGN_COMPLEXITY_BYID_SUCCESS,
  GET_STUDY_DESIGN_COMPLEXITY_BYID_FAILED,
  GET_STUDY_DESIGN_COMPLEXITY_BYID_NOTSTARTED,
  UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_STARTED,
  UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_SUCCESS,
  UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_FAILED,
  UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_NOTSTARTED,
  GET_PRODUCT_COMPLEXITY_TABLEDATA_STARTED,
  GET_PRODUCT_COMPLEXITY_TABLEDATA_SUCCESS,
  GET_PRODUCT_COMPLEXITY_TABLEDATA_FAILED,
  GET_PRODUCT_COMPLEXITY_TABLEDATA_NOTSTARTED,
  GET_SUPPLY_COMPLEXITY_TABLEDATA_STARTED,
  GET_SUPPLY_COMPLEXITY_TABLEDATA_SUCCESS,
  GET_SUPPLY_COMPLEXITY_TABLEDATA_FAILED,
  GET_SUPPLY_COMPLEXITY_TABLEDATA_NOTSTARTED,
  UPDATE_PRODUCT_COMPLEXITY_BYID_STARTED,
  UPDATE_PRODUCT_COMPLEXITY_BYID_SUCCESS,
  UPDATE_PRODUCT_COMPLEXITY_BYID_FAILED,
  UPDATE_PRODUCT_COMPLEXITY_BYID_NOTSTARTED,
  UPDATE_SUPPLY_COMPLEXITY_BYID_STARTED,
  UPDATE_SUPPLY_COMPLEXITY_BYID_SUCCESS,
  UPDATE_SUPPLY_COMPLEXITY_BYID_FAILED,
  UPDATE_SUPPLY_COMPLEXITY_BYID_NOTSTARTED,

  GET_BCSR_TABLEDATA_STARTED,
  GET_BCSR_TABLEDATA_SUCCESS,
  GET_BCSR_TABLEDATA_FAILED,
  GET_BCSR_TABLEDATA_NOTSTARTED,
  ADD_BCSR_FORMDATA_STARTED,
  ADD_BCSR_FORMDATA_SUCCESS,
  ADD_BCSR_FORMDATA_FAILED,
  ADD_BCSR_FORMDATA_NOTSTARTED,
  UPDATE_BCSR_DATA_BYID_STARTED,
  UPDATE_BCSR_DATA_BYID_SUCCESS,
  UPDATE_BCSR_DATA_BYID_FAILED,
  UPDATE_BCSR_DATA_BYID_NOTSTARTED,
  GET_ONE_BCSR_DATA_BYID_STARTED,
  GET_ONE_BCSR_DATA_BYID_SUCCESS,
  GET_ONE_BCSR_DATA_BYID_FAILED,
  GET_ONE_BCSR_DATA_BYID_NOTSTARTED,
  BCSRRiskAssessmentFormDATA,
  bcsr_Risk_Parent_Closed,
  bcsr_Risk_Child_Closed,
  bcsr_Risk_Both_Closed,
  COUNTRY_DETAILS_FOR_MODAL,
  UPDATE_OVERALLRISK_INCOMPLEXITY_STARTED,
  UPDATE_OVERALLRISK_INCOMPLEXITY_SUCCESS,
  UPDATE_OVERALLRISK_INCOMPLEXITY_FAILED,
  UPDATE_OVERALLRISK_INCOMPLEXITY_NOTSTARTED,
  SET_COMPOUNDLIST_FROM_LANDLING_SCREEN,
  FAILED_ORDER_DEPOTLIST_STARTED,
  FAILED_ORDER_DEPOTLIST_SUCCESS,
  FAILED_ORDER_DEPOTLIST_FAILED,
  FAILED_ORDER_DEPOTLIST_NOTSTARTED
} from "../types/types";
import { getTheme } from "../../utils/commonUtils";
import * as moment from "moment";
export const loadState = {
  NOTSTARTED: 'NOTSTARTED',
  STARTED: 'STARTED',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

const initialState = {
  showLoading: false,
  notification: {
    type: "",
    message: "",
    description: "",
    status: false,
  },
  riskLevel: 'Low',
  theme: getTheme(),
  navigationData: {},
  isMenuCollapsed: true,
  showFeedback: false,
  search: null,
  selectedoption: null,
  expectedResolutionSlideVal: "",
  filterAllData: [],
  complexityLevel: "",
  showClosed: false,
  data: [],
  summaryList: [],
  riskActionPayload: {},
  demandsupplytabledata: [],
  demandsupplytabledataLoadState: loadState.NOTSTARTED,
  inventoryandsiteshipmenttabledata: [],
  inventoryandsiteshipmenttabledataLoadState: loadState.NOTSTARTED,

  getStudyDesignComplexityLoadstate: loadState.NOTSTARTED,
  studyDesignComplexityData: {},
  updateStudyDesignComplexityLoadstate: loadState.NOTSTARTED,
  productComplexityTableDataLoadState: loadState.NOTSTARTED,
  productComplexityTableData: [],
  supplyComplexityTableDataLoadState: loadState.NOTSTARTED,
  supplyComplexityTableData: [],
  updateProductComplexityLoadState: loadState.NOTSTARTED,
  updateSupplyComplexityLoadState: loadState.NOTSTARTED,

  BCSRRiskAssessmentFormvalue: [],//for ui data save 
  bcsrRiskClosedApisHandle: {
    parentStatusClosed: false,
    childrenStatusClosed: false,
    bothStatusClosed: false,
  },
  BCSRTABLEDATA: [],
  BCSRTABLEDATALoadState: loadState.NOTSTARTED,
  oneBCSRDataBYID: {},
  oneBCSRDataBYIDLoadState: loadState.NOTSTARTED,
  addBCSRFormDataLoadState: loadState.NOTSTARTED,
  updateBCSRDataBYIDLoadState: loadState.NOTSTARTED,
  countryDetailsTwin: {},
  updateOverAllRiskInComplexityLoadState: loadState.NOTSTARTED,
  portfolioCompoundList: [],
  failedOrderDepotsLoadState: loadState.NOTSTARTED,
  failedorderDepotsList: {
    alertName: 'Failed Order',
    failedorderDepotsListArray: []
  },
};


export default (state = initialState, action) => {

  switch (action.type) {
    case SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case SELECTED_OPTION:
      return {
        ...state,
        selectedoption: action.payload,
      };
    case TOGGLE_MENU:
      return {
        ...state,
        isMenuCollapsed: !state.isMenuCollapsed,
      };
    case SHOW_LOADING:
      return {
        ...state,
        showLoading: action.payload,
      };
    case HIDE_LOADING:
      return {
        ...state,
        showLoading: action.payload,
      };
    case SHOW_NOTIFICATION:
      return {
        ...state,
        notification: {
          status: true,
          ...action.payload,
        },
      };
    case HIDE_NOTIFICATION:
      return {
        ...state,
        notification: {
          status: false,
        },
      };
    case SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case SET_NAVIGATION_DATA:
      return {
        ...state,
        navigationData: action.payload,
      };
    case SET_RISK_LEVEL:
      return {
        ...state,
        riskLevel: action.payload,
      };
    case SET_MILESTONE_DATA:
      return {
        ...state,
        milestoneData: action.payload,
      };
    case RISK_ACTION_SUMMARY_FILTER:
      return {
        ...state,
        riskActionPayload: action.payload
      };
    case COMPLEXITY_LEVEL: {
      return {
        ...state,
        complexityLevel: action.payload
      }

    }
    case STUDY_DETAILS:
      return {
        ...state,
        currentStudyDetails: action.payload,
      };
    case SET_CURRENT_SCREEN:
      return {
        ...state,
        currentScreen: action.payload,
      };
    case SET_SUPPLY_DATA:
      return {
        ...state,
        supplyDatas: action.payload,
      };
    case SET_ROLECONFIG_DATA:
      return {
        ...state,
        roleConfigData: action.payload,
      };
    case SET_USERROLECONFIG_DATA:
      return {
        ...state,
        userRoleConfigData: action.payload,
      };
    case IS_LANDING:
      return {
        ...state,
        isLanding: action.payload,
      };
    case SHOW_FEEDBACK:
      return {
        ...state,
        showFeedback: !state.showFeedback,
      };
    case SELECTED_ITEM:
      return {
        ...state,
        selectedItem1: action.payload,
      };
    case COMPOUND_LIST:
      return {
        ...state,
        compoundlist: action.payload,
      };
    case IS_EXPAND:
      return {
        ...state,
        isExpand: action.payload,
      };
    case SELECTED_TYPE:
      return {
        ...state,
        selectedFlowTypeName: action.payload,
      };
    case SET_ALERT_MATERIAL:
      return {
        ...state,
        alertMaterial: action.payload,
      };
    case NODE_ID:
      return {
        ...state,
        nodeIds: action.payload,
      };
    case SET_COMPOUND_ID:
      return {
        ...state,
        compoundIds: action.payload,
      };
    case SET_LINK_OBJECT:
      return {
        ...state,
        linkObject: action.payload,
      };
    case ACTUAL_SUBJECT:
      return {
        ...state,
        actualSub: action.payload,
      };
    case SHOW_CHART:
      return {
        ...state,
        showWidgetChart: action.payload,
      };
    case EXPECTED_RESOLUTION_SLIDE:
      return {
        ...state,
        expectedResolutionSlideVal: action.payload,
      };
    case SET_LINK_OBJECT:
      return {
        ...state,
        linkObject: action.payload,
      };
    case ACTUAL_SUBJECT:
      return {
        ...state,
        actualSub: action.payload,
      };
    case SHOW_CHART:
      return {
        ...state,
        showWidgetChart: action.payload,
      };
    case EXPECTED_RESOLUTION_SLIDE:
      return {
        ...state,
        expectedResolutionSlideVal: action.payload,
      };
    case TESTVALUESDATA:
      return {
        ...state,
        expectedTestvalue: action.payload,
      };
    case INITIAL_lOAD_OPEN_VERSION:
      return {
        ...state,
        openTab: action.payload,
      };
    case SET_Filter_DATA:
      return {
        ...state,
        filterAllData: action.payload,
      };
    case SET_COMPOUND_LIST:
      return {
        ...state,
        compoundList: action.payload,
      };
    case SET_POTFOILIO:
      return {
        ...state,
        portfolioList: action.payload,
      };
    case SET_SUB_CATEGORY:
      return {
        ...state,
        subCategory: action.payload,
      };
    case RISK_ACTION:
      return {
        ...state,
        riskData: action.payload,
      };
    case GET_MULTIPLE_DEPOT_SELECTION:
      return {
        ...state,
        multipleDepotData: action.payload,
      }
    case CHECK_LIST_TOGGEL:
      return {
        ...state,
        checkListData: action.payload,
      };

    case FGDATA:
      return {
        ...state,
        expectedData: action.payload,
      };

    case TOGGLE_CLOSED:
      return {
        ...state,
        showClosed: !state?.showClosed,
        summaryList: filterData(state.data, !state.showClosed),
      };

    case SET_SUMMARY_DATA:
      return {
        ...state,
        data: action?.data,
        summaryList: filterData(action.data, state.showClosed),
      };

    case RESET_SHOW_CLOSED:
      return {
        ...state,
        showClosed: false, // Reset only the showClosed state to its initial value
      };

    case FGCOUNTRYDATA:
      return {
        ...state,
        expectedCountry: action.payload,
      };

    case GET_DEMAND_AND_SUPPLY_TABLEDATA_STARTED:
      return {
        ...state,
        demandsupplytabledataLoadState: loadState.STARTED,
        apiError: false,
      };

    case GET_DEMAND_AND_SUPPLY_TABLEDATA_SUCCESS:
      return {
        ...state,
        demandsupplytabledata: [...action.payload],
        demandsupplytabledataLoadState: loadState.SUCCESS,
        apiError: false,
      };

    case GET_DEMAND_AND_SUPPLY_TABLEDATA_FAILED:
      return {
        ...state,
        demandsupplytabledataLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };

    case GET_DEMAND_AND_SUPPLY_TABLEDATA_NOTSTARTED:
      return {
        ...state,
        demandsupplytabledataLoadState: loadState.NOTSTARTED,
      };

    case GET_INVENTORY_AND_SITE_SHIPEMENTDATA_STARTED:
      return {
        ...state,
        inventoryandsiteshipmenttabledataLoadState: loadState.STARTED,
        apiError: false,
      };

    case GET_INVENTORY_AND_SITE_SHIPEMENTDATA_SUCCESS:
      return {
        ...state,
        inventoryandsiteshipmenttabledata: [...action.payload],
        inventoryandsiteshipmenttabledataLoadState: loadState.SUCCESS,
        apiError: false,
      };

    case GET_INVENTORY_AND_SITE_SHIPEMENTDATA_FAILED:
      return {
        ...state,
        inventoryandsiteshipmenttabledataLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };

    case GET_INVENTORY_AND_SITE_SHIPEMENTDATA_NOTSTARTED:
      return {
        ...state,
        inventoryandsiteshipmenttabledataLoadState: loadState.NOTSTARTED,
      };

    case GET_STUDY_DESIGN_COMPLEXITY_BYID_STARTED:
      return {
        ...state,
        getStudyDesignComplexityLoadstate: loadState.STARTED,
        apiError: false,
      };

    case GET_STUDY_DESIGN_COMPLEXITY_BYID_SUCCESS:
      return {
        ...state,
        studyDesignComplexityData: action.payload,
        getStudyDesignComplexityLoadstate: loadState.SUCCESS,
        apiError: false,
      };


    case GET_STUDY_DESIGN_COMPLEXITY_BYID_FAILED:
      return {
        ...state,
        getStudyDesignComplexityLoadstate: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };

    case GET_STUDY_DESIGN_COMPLEXITY_BYID_NOTSTARTED:
      return {
        ...state,
        getStudyDesignComplexityLoadstate: loadState.NOTSTARTED,
      };

    case UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_STARTED:
      return {
        ...state,
        updateStudyDesignComplexityLoadstate: loadState.STARTED,
        apiError: false,
      };

    case UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_SUCCESS:
      return {
        ...state,
        updateStudyDesignComplexityLoadstate: loadState.SUCCESS,
        apiError: false,
      };


    case UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_FAILED:
      return {
        ...state,
        updateStudyDesignComplexityLoadstate: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };

    case UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_NOTSTARTED:
      return {
        ...state,
        updateStudyDesignComplexityLoadstate: loadState.NOTSTARTED,
      };

    case GET_PRODUCT_COMPLEXITY_TABLEDATA_STARTED:
      return {
        ...state,
        productComplexityTableDataLoadState: loadState.STARTED,
        apiError: false,
      };

    case GET_PRODUCT_COMPLEXITY_TABLEDATA_SUCCESS:
      const transformedData = [];
      // Loop through the original data array
      action.payload.forEach(item => {
        // Loop through each supply complexity object inside the "productComplexity" array
        item.productComplexity.forEach(complexity => {
          // Create a new object with the required fields for the table
          const transformedItem = {
            ...complexity,
            type: item.type,
          };
          // Push the transformed object to the transformedData array
          transformedData.push(transformedItem);
        });
      });
      return {
        ...state,
        productComplexityTableData: transformedData,
        productComplexityTableDataLoadState: loadState.SUCCESS,
        apiError: false,
      };


    case GET_PRODUCT_COMPLEXITY_TABLEDATA_FAILED:
      return {
        ...state,
        productComplexityTableDataLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };

    case GET_PRODUCT_COMPLEXITY_TABLEDATA_NOTSTARTED:
      return {
        ...state,
        productComplexityTableDataLoadState: loadState.NOTSTARTED,
      };

    case GET_SUPPLY_COMPLEXITY_TABLEDATA_STARTED:
      return {
        ...state,
        supplyComplexityTableDataLoadState: loadState.STARTED,
        apiError: false,
      };

    case GET_SUPPLY_COMPLEXITY_TABLEDATA_SUCCESS:
      const SUPPLY_COMPLEXITY_Data = [];

      action.payload.forEach(item => {
        item.supplyComplexity.forEach(complexity => {
          const transformedItem = {
            ...complexity,
            type: item.type,
          };
          SUPPLY_COMPLEXITY_Data.push(transformedItem);
        });
      });
      return {
        ...state,
        supplyComplexityTableData: SUPPLY_COMPLEXITY_Data,
        supplyComplexityTableDataLoadState: loadState.SUCCESS,
        apiError: false,
      };


    case GET_SUPPLY_COMPLEXITY_TABLEDATA_FAILED:
      return {
        ...state,
        supplyComplexityTableDataLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };

    case GET_SUPPLY_COMPLEXITY_TABLEDATA_NOTSTARTED:
      return {
        ...state,
        supplyComplexityTableDataLoadState: loadState.NOTSTARTED,
      };

    case UPDATE_PRODUCT_COMPLEXITY_BYID_STARTED:
      return {
        ...state,
        updateProductComplexityLoadState: loadState.STARTED,
        apiError: false,
      };

    case UPDATE_PRODUCT_COMPLEXITY_BYID_SUCCESS:
      return {
        ...state,
        updateProductComplexityLoadState: loadState.SUCCESS,
        apiError: false,
      };


    case UPDATE_PRODUCT_COMPLEXITY_BYID_FAILED:
      return {
        ...state,
        updateProductComplexityLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };

    case UPDATE_PRODUCT_COMPLEXITY_BYID_NOTSTARTED:
      return {
        ...state,
        updateProductComplexityLoadState: loadState.NOTSTARTED,
      };


    case UPDATE_SUPPLY_COMPLEXITY_BYID_STARTED:
      return {
        ...state,
        updateSupplyComplexityLoadState: loadState.STARTED,
        apiError: false,
      };

    case UPDATE_SUPPLY_COMPLEXITY_BYID_SUCCESS:
      return {
        ...state,
        updateSupplyComplexityLoadState: loadState.SUCCESS,
        apiError: false,
      };


    case UPDATE_SUPPLY_COMPLEXITY_BYID_FAILED:
      return {
        ...state,
        updateSupplyComplexityLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };

    case UPDATE_SUPPLY_COMPLEXITY_BYID_NOTSTARTED:
      return {
        ...state,
        updateSupplyComplexityLoadState: loadState.NOTSTARTED,
      };

    case GET_BCSR_TABLEDATA_STARTED:
      return {
        ...state,
        BCSRTABLEDATALoadState: loadState.STARTED,
        apiError: false,
      };

    case GET_BCSR_TABLEDATA_SUCCESS:
      const modifiedBCSRTABLEDATA = action.payload.map((item) => {

        const CompoundFormatted = item?.compound ? item?.compound.toString() : null;
        const dateOccuredFormatted = item?.dateOccured
          ? moment.utc(item?.dateOccured).format("DD-MMM-YYYY")
          : null;
        const expectedResolutionDateFormatted = item?.expectedResolutionDate
          ? moment.utc(item?.expectedResolutionDate).format("DD-MMM-YYYY")
          : null;

        return {
          ...item,
          CompoundFormatted: CompoundFormatted,
          dateOccuredFormatted: dateOccuredFormatted,
          expectedResolutionDateFormatted: expectedResolutionDateFormatted,
        };
      });

      return {
        ...state,
        BCSRTABLEDATA: [...modifiedBCSRTABLEDATA],
        BCSRTABLEDATALoadState: loadState.SUCCESS,
        apiError: false,
      };

    case GET_BCSR_TABLEDATA_FAILED:
      return {
        ...state,
        BCSRTABLEDATALoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };

    case GET_BCSR_TABLEDATA_NOTSTARTED:
      return {
        ...state,
        BCSRTABLEDATALoadState: loadState.NOTSTARTED,
      };

    case GET_ONE_BCSR_DATA_BYID_STARTED:
      return {
        ...state,
        oneBCSRDataBYIDLoadState: loadState.STARTED,
        apiError: false,
      };

    case GET_ONE_BCSR_DATA_BYID_SUCCESS:
      let updatedRiskAssessment = [];
      if (action.payload && action.payload.riskAssessment?.length > 0) {
        updatedRiskAssessment = action.payload.riskAssessment.map((item, index) => {
          const newItem = {
            ...item,
            id: index + 1,
            key: `riskAssessment_${index + 1}`,// Adding a unique key
            alreadyClosed: item.protocolStatus === 'Closed' ? true : false
          };
          return newItem;
        }
        );
      }
      // Create a new copy of the action payload with updated riskAssessment
      const updatedPayload = {
        ...action.payload,
        riskAssessment: updatedRiskAssessment
      };

      return {
        ...state,
        oneBCSRDataBYID: updatedPayload,
        oneBCSRDataBYIDLoadState: loadState.SUCCESS,
        apiError: false,
      };



    case GET_ONE_BCSR_DATA_BYID_FAILED:
      return {
        ...state,
        oneBCSRDataBYIDLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };

    case GET_ONE_BCSR_DATA_BYID_NOTSTARTED:
      return {
        ...state,
        oneBCSRDataBYIDLoadState: loadState.NOTSTARTED,
      };

    case ADD_BCSR_FORMDATA_STARTED:
      return {
        ...state,
        addBCSRFormDataLoadState: loadState.STARTED,
        apiError: false,
      };

    case ADD_BCSR_FORMDATA_SUCCESS:
      return {
        ...state,
        addBCSRFormDataLoadState: loadState.SUCCESS,
        apiError: false,
      };

    case ADD_BCSR_FORMDATA_FAILED:
      return {
        ...state,
        addBCSRFormDataLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };

    case ADD_BCSR_FORMDATA_NOTSTARTED:
      return {
        ...state,
        addBCSRFormDataLoadState: loadState.NOTSTARTED,
      };


    case UPDATE_BCSR_DATA_BYID_STARTED:
      return {
        ...state,
        updateBCSRDataBYIDLoadState: loadState.STARTED,
        apiError: false,
      };

    case UPDATE_BCSR_DATA_BYID_SUCCESS:
      return {
        ...state,
        updateBCSRDataBYIDLoadState: loadState.SUCCESS,
        apiError: false,
      };

    case UPDATE_BCSR_DATA_BYID_FAILED:
      return {
        ...state,
        updateBCSRDataBYIDLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };

    case UPDATE_BCSR_DATA_BYID_NOTSTARTED:
      return {
        ...state,
        updateBCSRDataBYIDLoadState: loadState.NOTSTARTED,
      };

    case BCSRRiskAssessmentFormDATA:
      return {
        ...state,
        BCSRRiskAssessmentFormvalue: action.payload
      };

    case bcsr_Risk_Parent_Closed:
      return {
        ...state,
        bcsrRiskClosedApisHandle: {
          ...state.bcsrRiskClosedApisHandle,
          parentStatusClosed: action.payload
        }
      };

    case bcsr_Risk_Child_Closed:
      return {
        ...state,
        bcsrRiskClosedApisHandle: {
          ...state.bcsrRiskClosedApisHandle,
          childrenStatusClosed: action.payload
        }
      };

    case bcsr_Risk_Both_Closed:
      return {
        ...state,
        bcsrRiskClosedApisHandle: {
          ...state.bcsrRiskClosedApisHandle,
          bothStatusClosed: action.payload
        }
      };

    case COUNTRY_DETAILS_FOR_MODAL:
      return {
        ...state,
        countryDetailsTwin: action.payload
      }

    case UPDATE_OVERALLRISK_INCOMPLEXITY_STARTED:
      return {
        ...state,
        updateOverAllRiskInComplexityLoadState: loadState.STARTED,
        apiError: false,
      };

    case UPDATE_OVERALLRISK_INCOMPLEXITY_SUCCESS:
      return {
        ...state,
        updateOverAllRiskInComplexityLoadState: loadState.SUCCESS,
        apiError: false,
      };

    case UPDATE_OVERALLRISK_INCOMPLEXITY_FAILED:
      return {
        ...state,
        updateOverAllRiskInComplexityLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };

    case UPDATE_OVERALLRISK_INCOMPLEXITY_NOTSTARTED:
      return {
        ...state,
        updateOverAllRiskInComplexityLoadState: loadState.NOTSTARTED,
      };
    case SET_COMPOUNDLIST_FROM_LANDLING_SCREEN:
      return {
        ...state,
        portfolioCompoundList: action.payload,
      };


    case FAILED_ORDER_DEPOTLIST_STARTED:
      return {
        ...state,
        failedOrderDepotsLoadState: loadState.STARTED,
        apiError: false,
      };

    case FAILED_ORDER_DEPOTLIST_SUCCESS:
      return {
        ...state,
        failedOrderDepotsLoadState: loadState.SUCCESS,
        failedorderDepotsList: {
          ...state.failedorderDepotsList, // Keep existing alertName
          failedorderDepotsListArray: action.payload // Update only the array
        },
        apiError: false,
      };

    case FAILED_ORDER_DEPOTLIST_FAILED:
      return {
        ...state,
        failedOrderDepotsLoadState: loadState.FAILED,
        apiError: true,
        error: action.payload,
      };

    case FAILED_ORDER_DEPOTLIST_NOTSTARTED:
      return {
        ...state,
        failedOrderDepotsLoadState: loadState.NOTSTARTED,
      };

    default:
      return state;
  }
};

// Helper function to filter data
function filterData(data, showClosed) {
  if (showClosed) {
    return data.filter((item) => item.status !== "Active");
  } else {
    return data.filter((item) => item.status === "Active");
  }
}
