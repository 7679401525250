import React, { lazy, useEffect, useState } from "react";
import { Route, Redirect, Switch, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../components/Loading";
import Notification from "../components/Notification";
import SuspenseWrapper from "../components/SuspenseWrapper";
import * as QueryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { getAppPermission } from "../api/login";
import { APP_AUTH } from "../constants/apiBaseUrl";
import {CONFIG} from "../constants/configuration"
import alpsIcon from "../assets/icons/A_logo.png"
import bmsIcon from "../assets/icons/Celgene_cassa.jpg"

// APP ROUTE COMPONENTS
const Dashboard = lazy(() => import("./dashboard"));
// import Dashboard from "./dashboard";
const Account = lazy(() => import("./user"));

const App = () => {
  const location = useLocation();

  const history = useHistory();
  const { user_details } = QueryString.parse(location.search);
  const [loginBool, setLoginBool] = useState(false);

  const getUserConfigByRoles = async () => {
    
    const payload = { appId: 1 };

    // switch (res.landingPage) {
    //   case "Workbench":
    //     return history.push("/dashboard/landingPage");
    //   case "Compound Analysis and Insights":
    //     return history.push("/dashboard/compounds");
    //   case "Study List":
    //     return history.push("/dashboard/studies");
    //   case "Feedback":
    //     return history.push("/dashboard/feedback");

    //   default:
    //     return history.push("/dashboard/home");
    // }
    //
    let routes = []
    const res = await getAppPermission(payload);
    res.modules.map((record)=> record?.permission?.read && routes.push(record?.name))
    localStorage.setItem("routes",[...routes])
    localStorage.setItem("landingPage",res?.landingPage)
    let pageload = ""
    switch (res.landingPage) {
      case "Clinical Supply Operational Workbench":
        pageload= "/dashboard/landingPage"
        break;
      case "Compound/Program Analysis and Insights":
        pageload= "/dashboard/compounds"
        break;
      case "Study Portfolio":
        pageload= "/dashboard/studies"
        break;
      case "Reports":
        pageload= "/dashboard/reports/patient-enrollment"
        break;
      default:
        pageload= "/dashboard/home"
        break;
    }
    history.replace({ pathname: pageload, search: "", state: {} });
  };

  useEffect(() => {
    if (user_details) {
      let obj = JSON.parse(user_details);
      localStorage.setItem(
        "user_details",
        JSON.stringify({ accessToken: obj.accessToken, username: obj.username })
      );

      getUserConfigByRoles();
    } else {
      setLoginBool(true);
    }
  }, [user_details]);

  const match = useRouteMatch();
  const showLoading = useSelector((state) => state.commonReducer.showLoading);

  let imgPath = document.getElementById("favIcon")

  if(CONFIG.product === "RL"){
    imgPath.href=alpsIcon
  }
  else if (CONFIG.product === "BMS") {
    imgPath.href=bmsIcon
  }

  return (
    <>
      <Loading show={showLoading} />
      <Notification />
      <div style={{ opacity: showLoading ? 0.5 : 1 }}>
        <SuspenseWrapper>
          <Switch>
            <Route path={`${match.url}dashboard`} key="dashboard">
              <Dashboard />
            </Route>
            <Route path={`${match.url}user`} key="user">
              <Account />
            </Route>

            {loginBool && (
              <Route
                exact
                path="/"
                key="login"
                component={() => {
                  window.location.href = APP_AUTH;
                  // window.location.href = "http://localhost:3004/#/";
                  return null;
                }}
              />
            )}
            {/* <Route exact path="/" key="login">
             <Redirect to={"/user/login"} />
            </Route> */}
          </Switch>
        </SuspenseWrapper>
      </div>
    </>
  );
};

export default App;
