import { API_CASSA, API_CASSA_DEV_DEMO } from "../../constants/apiBaseUrl";
import { AjaxService } from "../../utils/AjaxService";

// get Country Summary
export const getCountrySummary = (body_data) => {
  return AjaxService.post(
    `${API_CASSA_DEV_DEMO}/api/countryprofile/getCountrySummary`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};


// get All DepotsByCountryAndStudy
export const getAllDepotsByCountryAndStudy = (_queryParam, body_data) => {
  return AjaxService.post(
    `${API_CASSA_DEV_DEMO}/api/depotprofile/getAllDepotsByCountryAndStudy?country=${_queryParam?.country}`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};


//  Inventory AtSitesForDigitalTwin
export const getInventoryAtSitesForDigitalTwin = (body_data) => {
  return AjaxService.post(
    `${API_CASSA_DEV_DEMO}/api/siteprofile/getInventoryAtSitesForDigitalTwin`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};

//   Inbound Shipments At Sites DigitalTwin
export const getInboundShipmentsAtSitesForDigitalTwin = (body_data) => {
  return AjaxService.post(
    `${API_CASSA_DEV_DEMO}/api/siteprofile/getInboundShipmentsAtSitesForDigitalTwin`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};

// Patient Enrollment and upcoming planned Visit
export const getPatientEnrollmentandVisitDetailsForDigitalTwin = (body_data) => {
  return AjaxService.post(
    `${API_CASSA_DEV_DEMO}/api/siteprofile/getPatientEnrollmentandVisitDetailsForDigitalTwin`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};

// get All Sites Info for DigitalTwin
export const getAllSitesInfoforDigitalTwin = (body_data) => {
  return AjaxService.post(
    `${API_CASSA_DEV_DEMO}/api/siteprofile/getAllSitesInfoforDigitalTwin`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};


//inventory and site shipment togther in digital twin
export const getInventoryAndSiteShipmentsDetailsForDigitalTwin = (body_data) => {
  return AjaxService.post(
    `${API_CASSA_DEV_DEMO}/api/siteprofile/getInventoryAndSiteShipmentsForDigitalTwin`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};

//inventory and site shipment togther count in digital twin
export const getInventoryAndSiteShipmentsCount = (body_data) => {
  return AjaxService.post(
    `${API_CASSA_DEV_DEMO}/api/siteprofile/getInventoryAndSiteShipmentsCountForDigitalTwin`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};



export const getSiteRiskDetailByCountryAndSiteFilter = (body_data) => {
  return AjaxService.post(
    `${API_CASSA_DEV_DEMO}/api/siteprofile/getSiteRiskDetailByCountryAndSiteFilter`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};

//multiple country apis ============
export const getMultipleCountryInfo = (body_data) => {
  return AjaxService.post(
    `${API_CASSA_DEV_DEMO}/api/countryprofile/getMultipleCountryInfo`,
    JSON.stringify(body_data)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getAllCountryProfilesWithStudies = (body_data) => {
  return AjaxService.post(
    `${API_CASSA_DEV_DEMO}/api/countryprofile/getAllCountryProfilesWithStudies`,
    JSON.stringify(body_data)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};
//multiple country apis ============