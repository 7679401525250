import {
    API_CASSA,
} from "../../constants/apiBaseUrl";
import { AjaxService } from "../../utils/AjaxService";

// Study Detail Controller

//get StudyDesignComplexity
export const getStudyDesignComplexity = (_queryParam) => {
    return AjaxService.get(
        `${API_CASSA}/api/basicstudydetails/getStudyDesignComplexity?studyId=${_queryParam}`,
    ).then(
        (res) => res.data,
        (error) => {
            throw error.response.data;
        }
    );
};

//update StudyDesignComplexity
export const updateStudyDesignComplexity = (body_data) => {
    return AjaxService.post(
        `${API_CASSA}/api/basicstudydetails/saveAndUpdateStudyDesignComplexity`,
        JSON.stringify(body_data)
    ).then(
        (res) => res.data,
        (error) => {
            throw error.response.data;
        }
    );
};


//get product Complexity data
export const getProductComplexityData = (_queryParam) => {
    return AjaxService.get(
        `${API_CASSA}/api/basicstudydetails/getProductComplexityByStudy?studyId=${_queryParam}`,
    ).then(
        (res) => res.data,
        (error) => {
            throw error.response.data;
        }
    );
};

//get supply Complexity data
export const getSupplyComplexityData = (_queryParam) => {
    return AjaxService.get(
        `${API_CASSA}/api/basicstudydetails/getSupplyComplexityByStudy?studyId=${_queryParam}`,
    ).then(
        (res) => res.data,
        (error) => {
            throw error.response.data;
        }
    );
};

//get supply Complexity data
export const updateOverAllRiskInComplexity = (body_data) => {
    return AjaxService.post(
        `${API_CASSA}/api/basicstudydetails/updateOverAllRisk`,
        JSON.stringify(body_data)
    ).then(
        (res) => res.data,
        (error) => {
            throw error.response.data;
        }
    );
};

//save the edited product Complexity data
export const saveProductComplexity = (body_data) => {
    return AjaxService.post(
        `${API_CASSA}/api/basicstudydetails/saveProductComplexity`,
        JSON.stringify(body_data)
    ).then(
        (res) => res.data,
        (error) => {
            throw error.response.data;
        }
    );
};

//save the edited supply Complexity data
export const saveSupplyComplexity = (body_data) => {
    return AjaxService.post(
        `${API_CASSA}/api/basicstudydetails/saveSupplyComplexity`,
        JSON.stringify(body_data)
    ).then(
        (res) => res.data,
        (error) => {
            throw error.response.data;
        }
    );
};

//get auto-logic risk for study design
export const getStudyDesignComplexityAutoRisk = (studyId) => {
    return AjaxService.get(
        `${API_CASSA}/api/basicstudydetails/getStudyDesignComplexityAutoRisk?studyId=${studyId}`,
    ).then(
        (res) => res.data,
        (error) => {
            throw error.response.data;
        }
    );
};

//get auto-logic risk for product or suppply complexity
export const getProductSupplyComplexityAutoRisk = (_queryParam) => {
    return AjaxService.get(
        `${API_CASSA}/api/basicstudydetails/getProductOrSupplyComplexityAutoRisk?studyId=${_queryParam.studyId}&tableName=${_queryParam.tableName}`,
    ).then(
        (res) => res.data,
        (error) => {
            throw error.response.data;
        }
    );
};

//update risk level of study design  complexity
export const updateStudyComplexityRisk = (queryParam) => {
    return AjaxService.post(
        `${API_CASSA}/api/basicstudydetails/updateStudyDesignRiskLevel?studyId=${queryParam.studyId}&riskLevel=${queryParam.riskLevel}`,
    ).then(
        (res) => res.data,
        (error) => {
            throw error.response.data;
        }
    );
};