export const getTheme = () => {
  let theme = "";
  let today = new Date().getHours();
  // If current time is between 7AM to 7PM
  if (today >= 7 && today <= 18) {
    theme = "light";
  } else {
    theme = "light";
  }
  return theme;
};

export const getTimeImage = (val) => {
  var value = "";

  if (val >= 0 && val <= 30) {
    value = "red";
  }
  if (val >= 31 && val <= 90) {
    value = "orange";
  }
  if (val > 90) {
    value = "green";
  }

  return value;
};

export const getUser = () => {
  return JSON.parse(localStorage.getItem("user_details"));
}

export const genericSorter = (a, b) => {
  // Check if both values are numbers
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }

  // Check if both values are dates
  if (a instanceof Date && b instanceof Date) {
    return a.getTime() - b.getTime();
  }

  // For other types or strings, use localeCompare
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }

  // Default fallback for unknown types
  return 0;
};

//check perticular value is array of object
export const isArrayOfObject = (arrayOfObject) => {
  return Array.isArray(arrayOfObject) && arrayOfObject.every((item) =>  typeof item === 'object')
}

//check perticular value is an array 
export const isArray = (array) => Array.isArray(array)

export const isEmpty = (str) => {
  return  !str || str.trim() === '';
}

export const capitalizeFirstLetter = (str) => {
  if(!str) return ''
  return str.charAt(0).toUpperCase()+str.slice(1).toLowerCase()
}