import { API_CASSA, API_AUTHENTICATION_URL, API_RISK_ALERT_MONGO } from "../constants/apiBaseUrl";
import { AjaxService } from "../utils/AjaxService";
import { callBackgroundAPi } from "./riskTrendChart";

export const getAllHotTopic = (_queryParam) => {
  return AjaxService.get(
    `${API_CASSA}/api/HotTopics/getAllHotTopicsBcsr`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getHotTopicById = (id) => {
  return AjaxService.get(
    `${API_CASSA}/api/HotTopics/getHottopicById?id=${id}`
    // JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const saveHotTopicsForm = (_queryParam) => {
  return AjaxService.post(
    `${API_CASSA}/api/HotTopics/saveHottopics`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const updateHotTopicsForm = (_queryParam) => {
  return AjaxService.post(
    `${API_CASSA}/api/HotTopics/EditHottopics`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

//getAllCompoundNames
export const getAllCompoundNames = () => {
  return AjaxService.get(`${API_CASSA}/api/HotTopics/getAllCompoundNames`).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

// getAllStudyNames
export const getAllStudyNames = () => {
  return AjaxService.get(`${API_CASSA}/api/HotTopics/getAllStudyNames`).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

// getallusers
export const getAllUsers = () => {
  return AjaxService.get(
    `${API_AUTHENTICATION_URL}/api/user/allHotTopicUsers`
  ).then(
    (res) => res.data,

    (error) => {
      throw error.response.data;
    }
  );
};
//get profile country

export const getAllCountryByProfile = () => {
  return AjaxService.get(
    `${API_CASSA}/api/countryprofile/getAllCountryProfiles`
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

//delete file
export const getDeleteFile = (fileName) => {
  return AjaxService.delete(`${API_CASSA}/file/delete/${fileName}`).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

//DownloadFile file
export const getDownloadFile = (fileName) => {
  return AjaxService.get(`${API_CASSA}/file/download/${fileName}`).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getBCSRTableDataAPI = (_queryParam) => {
  return AjaxService.get(`${API_CASSA}/api/HotTopics/getAllHotTopicsBcsr`
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getBCSRDataByIDAPI = (id) => {
  return AjaxService.get(`${API_CASSA}/api/HotTopics/getHottopicBcsrById?id=${id}`
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const addBCSRFormDataAPI = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/HotTopics/saveHotTopicsBcsr`,
    _queryParam
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const updateBCSRDataByIDAPI = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/HotTopics/UpdateHottopicsBcsr`,
    _queryParam
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getcompoundIdbasedOnstudyId = (studyId) => {
  return AjaxService.get(`${API_CASSA}/api/HotTopics/getCompoundByStudy?studyId=${studyId}`
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getAllStudyNamesbasedOnCompoundsId = (CompoundsId) => {
  return AjaxService.post(`${API_CASSA}/api//HotTopics/getStudiesByCompound`,
    JSON.stringify(CompoundsId)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

//bcsr parent update
export const UpdateSmartAlertByBCSRParent = (_queryParam) => {
  return AjaxService.post(
    `${API_RISK_ALERT_MONGO}/api/alert-response/updateSmartAlertByBCSRParent`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};

//bcsr child update api
export const UpdateSmartAlertByStudyAssessment = (_queryParam) => {
  return AjaxService.post(
    `${API_RISK_ALERT_MONGO}/api/alert-response/updateSmartAlertByStudyAssessment`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};

//bcsr child newrisk id update in bcsr from dashboard
export const UpdateHotTopicsProtocolRiskStatus = (_queryParam) => {
  return AjaxService.post(
    `${API_CASSA}/api//HotTopics/updateHotTopicsProtocolRiskStatus`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};

//bcsr child update in study assement from bcsr
export const UpdateRiskAssessmentFromBCSRApi = (_queryParam) => {
  return AjaxService.post(
    `${API_CASSA}/api/basicstudydetails/UpdateRiskAssessmentFromBCSR`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => {
      callBackgroundAPi(_queryParam.studyId)
      return res
    },
    (error) => {
      throw error.response.data;
    }
  );
};

//bcsr child newrisk id update in study assement from dashboard
export const UpdateAssessmentDetailsWithNewRiskId = (_queryParam) => {
  return AjaxService.post(
    `${API_CASSA}/api/basicstudydetails/updateAssessmentDetailsWithNewRiskId`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => {
      callBackgroundAPi(_queryParam.studyId)
      return res
    },
    (error) => {
      throw error.response.data;
    }
  );
};

//update study from bcsr ,which was created from study
export const UpdateRiskAssessmentSummaryFromBCSR = (_queryParam) => {
  return AjaxService.post(
    `${API_CASSA}/api/basicstudydetails/UpdateRiskAssessmentSummaryFromBCSR`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => {
      callBackgroundAPi(_queryParam.studyId)
      return res
    },
    (error) => {
      throw error.response.data;
    }
  );
};

export const getAssestRefsByCompounds = (_queryParam) => {
  return AjaxService.post(
    `${API_CASSA}/api/HotTopics/getAssetRefByCompounds`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};