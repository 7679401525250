import { API_CASSA } from "../constants/apiBaseUrl";
import { AjaxService } from "../utils/AjaxService";

export const getAllBSCRCompounds = () => {
  return AjaxService.get(`${API_CASSA}/api/charts/getAllBSCRCompounds`).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getAllConfigDetails = () => {
  return AjaxService.get(`${API_CASSA}/api/charts/getAllConfigDetails`).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getBSCRChartByDurationAndStatus = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/charts/getBSCRChartByDurationAndStatus`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getBCSRHeatChart = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/charts/getBCSRHeatChart`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getBCSRPieChart = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/charts/getBCSRPieChart`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};


//background trigger api
export const callBackgroundAPi = (studyId) => {
  return AjaxService.get(`${API_CASSA}/api/charts/updateAlertCharts?studyId=${studyId}`).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getAllProgProdCompounds = () => {
  return AjaxService.get(`${API_CASSA}/api/charts/getAllAlertChartsCompounds`).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getProgProdChartByDurationAndStatus = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/charts/getProgramRiskChartByDurationAndStatus`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getAllAlertChartsCountries = () => {
  return AjaxService.get(`${API_CASSA}/api/charts/getAllAlertChartsCountries`).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getProgramRiskHeatChart = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/charts/getProgramRiskHeatChart`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getProgramPieChart = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/charts/getProgramRiskPieChart`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getProgramRiskHeatChartPopupData = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/charts/getProgramRiskHeatChartPopupData`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

//Product Risk
export const getAllProductCompounds = () => {
  return AjaxService.get(`${API_CASSA}/api/charts/getAllProductChartsCompounds`).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getAllProductChartsCountries = () => {
  return AjaxService.get(`${API_CASSA}/api/charts/getAllProductChartsCountries`).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};
export const getAllProductChartsMaterials = () => {
  return AjaxService.get(`${API_CASSA}/api/charts/getAllProductChartsMaterials`).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getAllProductChartsStudies = () => {
  return AjaxService.get(`${API_CASSA}/api/charts/getAllProductChartsStudies`).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getProductRiskHeatChart = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/charts/getProductRiskHeatChart`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getProductPieChart = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/charts/getProductRiskPieChart`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getProductRiskChartByDurationAndStatus = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/charts/getProductRiskChartByDurationAndStatus`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};
export const getProductRiskHeatChartPopupData = (_queryParam) => {
  return AjaxService.post(`${API_CASSA}/api/charts/getProductRiskHeatChartPopupData`, _queryParam).then(
    (res) => res?.data,
    (error) => {
      throw error.response.data;
    }
  );
};